import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import styled, { css } from "styled-components";
import Color from "../../../assets/colors";

// Progress Component //
export const ProgressIndicatorStyled = styled(CircularProgress)`
  && {
    color: ${Color.hiredlyPurple};
  }
`;

// Grid Components //
export const JobCard = styling(Grid)((props) => ({
  width: "100%",
  backgroundColor: "white",
  height: "146px",
  borderRadius: "10px",
  boxShadow: `4px 4px 10px rgba(0, 0, 0, 0.16)`,

  "@media (max-width: 640px)": {
    height: "224px",
  },
}));

export const JobDetailsTop = styling(Grid)((props) => ({
  height: "106px",
  padding: "12px",

  "@media (max-width: 640px)": {
    height: "152px",
  },
}));

export const JobDetailsBottom = styling(Grid)((props) => ({
  height: "40px",
  backgroundColor: `rgba(0, 0, 0, 0.04)`,
  borderBottomRightRadius: "10px",
  borderBottomLeftRadius: props.color ? " " : "10px",
  paddingLeft: "10px",
  paddingRight: "10px",

  "@media (max-width: 640px)": {
    height: "62px",
    padding: "0.875rem",
  },
}));

export const EmptyContainer = styling(Grid)({});

export const GridStyled = styled(Grid)`
  && {
    flex-direction: ${(props) => props.$reverse && "row-reverse"};
  }
`;

export const OurOfficeContainer = withTheme(styled(Grid)`
  && {
    flex-direction: column-reverse;
  }
`);

export const NavigationWrapper = withTheme(styled(Grid)`
  && {
    margin: 10px 0 20px 0;
    position: relative;
  }
`);

export const JobDescriptionContainer = withTheme(styled(Grid)`
  && {
    display: contents;
  }
`);

export const CompanyBannerContainer = withTheme(styled(Grid)`
  && {
    position: relative;
  }
`);

export const JobBannerContainer = withTheme(styled(Grid)`
  && {
    position: relative;
  }
`);

export const OurOfficeWrapper = withTheme(styled(Grid)`
  && {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
  }
`);

export const CompanyNameLocationSkeleton = withTheme(styled(Grid)`
  && {
    position: absolute;
    z-index: 2;
    bottom: 10%;
    left: 4%;
    width: 100%;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      left: 34.5%;
      bottom: 40%;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      left: 32%;
      bottom: 40%;
    }
  }
`);

export const JobInfoSkeletonWrapper = withTheme(styled(Grid)`
  && {
    margin: 4% 0;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    ${(props) =>
      props.tag === "single-job-page" &&
      css`
        max-width: 1100px;
        margin: 0 auto 4% auto;
      `}

    ${(props) =>
      props.tag === "job-list-page" &&
      css`
        padding: 4% 5%;
        margin: 0;
      `}

      ${(props) => props.theme.breakpoints.between("mobileL", "lg")} {
      ${(props) =>
        props.tag === "single-job-page" &&
        css`
          padding: 0 30px;
          margin: 4% auto;
        `}
    }

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      flex-direction: column-reverse;
    }
  }
`);

export const JobInfoSkeletonContainer = withTheme(styled(Grid)`
  && {
    width: 100%;
  }
`);

export const CompanyBannerTextContainer = withTheme(styled(Grid)`
  && {
    position: absolute;
    z-index: 2;
    bottom: 15%;
    left: 4%;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      left: 4%;
      top: 58%;
    }

    ${(props) => props.theme.breakpoints.down("mobile")} {
      left: 4%;
      bottom: 10%;
    }
  }
`);

export const CompanyBannerButtonsSkeleton = withTheme(styled(Grid)`
  && {
    visibility: hidden;

    ${(props) => props.theme.breakpoints.up("tablet")} {
      visiblity: hidden;
    }
    ${(props) => props.theme.breakpoints.down("tablet")} {
      visibility: visible;
      position: absolute;
      z-index: 2;
      bottom: 20%;
      left: 4%;
    }
  }
`);

export const CompanyBannerTitleContainer = withTheme(styled(Grid)`
  && {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 4%;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      left: 4%;
      top: 43%;
    }

    ${(props) => props.theme.breakpoints.down("mobile")} {
      left: 4%;
      bottom: 30%;
    }
  }
`);

export const CompanyInfoContainerSkeleton = withTheme(styled(Grid)`
  && {
    position: relative;
    ${(props) =>
      props.tag === "single-job-page" &&
      css`
        max-width: 1100px;
        margin: 4% auto 4% auto;
      `}

    ${(props) =>
      props.tag === "job-list-page" &&
      css`
        padding: 4% 5%;
        margin: 0;
      `}

      ${(props) => props.theme.breakpoints.between("mobileL", "lg")} {
      ${(props) =>
        props.tag === "single-job-page" &&
        css`
          padding: 0 30px;
          margin: 4% auto;
        `}
    }
  }
`);

export const CompanyInfoJobDescriptionContainer = withTheme(styled(Grid)`
  && {
    width: 45%;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      width: 95%;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      width: 90%;
      transform: translate(-3%);
    }
  }
`);

export const CompanyInfoTitleDescriptionContainer = withTheme(styled(Grid)`
  && {
    width: 45%;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      width: 95%;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      width: 90%;
      transform: translate(-3%);
    }
  }
`);

export const OurPeopleContainerSkeleton = withTheme(styled(Grid)`
  && {
    ${(props) =>
      props.tag === "single-job-page" &&
      css`
        max-width: 1100px;
        margin: 0 auto 4% auto;
      `}

    ${(props) =>
      props.tag === "job-list-page" &&
      css`
        padding: 4% 5%;
        margin: 0;
      `}

      ${(props) => props.theme.breakpoints.between("mobileL", "lg")} {
      ${(props) =>
        props.tag === "single-job-page" &&
        css`
          padding: 0 30px;
          margin: 4% auto;
        `}
    }
  }
`);

export const OurPeopleTextContainer = withTheme(styled(Grid)`
  && {
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: space-evenly;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      width: 100%;
    }
  }
`);

export const OurBenefitContainerSkeleton = withTheme(styled(Grid)`
  && {
    ${(props) =>
      props.tag === "single-job-page" &&
      css`
        max-width: 1100px;
        margin: 0 auto 4% auto;
      `}

    ${(props) =>
      props.tag === "job-list-page" &&
      css`
        padding: 4% 5%;
        margin: 0;
      `}

      ${(props) => props.theme.breakpoints.between("mobileL", "lg")} {
      ${(props) =>
        props.tag === "single-job-page" &&
        css`
          padding: 0 30px;
          margin: 4% auto;
        `}
    }
  }
`);

export const OurMediaContainerSkeleton = withTheme(styled(Grid)`
  && {
    ${(props) =>
      props.tag === "single-job-page" &&
      css`
        max-width: 1100px;
        margin: 0 auto 4% auto;
      `}

    ${(props) =>
      props.tag === "job-list-page" &&
      css`
        padding: 4% 5%;
        margin: 0;
      `}

      ${(props) => props.theme.breakpoints.between("mobileL", "lg")} {
      ${(props) =>
        props.tag === "single-job-page" &&
        css`
          padding: 0 30px;
          margin: 4% auto;
        `}
    }
  }
`);

export const OurOfficeContainerSkeleton = withTheme(styled(Grid)`
  && {
    ${(props) =>
      props.tag === "single-job-page" &&
      css`
        max-width: 1100px;
        margin: 0 auto 4% auto;
      `}

    ${(props) =>
      props.tag === "job-list-page" &&
      css`
        padding: 4% 5%;
        margin: 0;
      `}

      ${(props) => props.theme.breakpoints.between("mobileL", "lg")} {
      ${(props) =>
        props.tag === "single-job-page" &&
        css`
          padding: 0 30px;
          margin: 4% auto;
        `}
    }
  }
`);

export const OurJobsContainerSkeleton = withTheme(styled(Grid)`
  && {
    ${(props) =>
      props.tag === "single-job-page" &&
      css`
        max-width: 1100px;
        margin: 0 auto 4% auto;
      `}

    ${(props) =>
      props.tag === "job-list-page" &&
      css`
        padding: 4% 5%;
        margin: 0;
      `}

    ${(props) => props.theme.breakpoints.between("mobileL", "lg")} {
      ${(props) =>
        props.tag === "single-job-page" &&
        css`
          padding: 0 30px;
          margin: 4% auto;
        `}
    }
  }
`);

export const OurMoreVideoContainerSkeleton = withTheme(styled(Grid)`
  && {
    ${(props) =>
      props.tag === "single-job-page" &&
      css`
        max-width: 1100px;
        margin: 0 auto 4% auto;
      `}

    ${(props) =>
      props.tag === "job-list-page" &&
      css`
        padding: 4% 5%;
        margin: 0;
      `}

      ${(props) => props.theme.breakpoints.between("mobileL", "lg")} {
      ${(props) =>
        props.tag === "single-job-page" &&
        css`
          padding: 0 30px;
          margin: 4% auto;
        `}
    }
  }
`);

export const RecommendedJobsContainerSkeleton = withTheme(styled(Grid)`
  && {
    margin: 0 auto 4% auto;
    max-width: 1100px;

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      margin: 0;
      padding: 0;
    }
  }
`);

export const PaginationPageNumbersContainerSkeleton = styled(Grid)`
  && {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
    margin-bottom: 5%;
  }
`;

// Skeleton Components //
export const PrimaryTextSkeleton = styling(Skeleton)({
  borderRadius: "1rem",
});

export const SecondaryTextSkeleton = styling(Skeleton)({
  backgroundColor: Color.secondaryGrey,
  borderRadius: "1rem",
});

export const ProfileImageSkeleton = styling(Skeleton)({
  //
});

export const TitleSkeleton = styling(Skeleton)({
  borderRadius: "0.5rem",
});

export const AiJobSummaryEmptyImageSkeleton = styling(Skeleton)({
  borderRadius: "0.5rem",
});

export const JobBannerImageSkeleton = withTheme(styled(Skeleton)`
  width: 100%;
  height: 100%;

  ${(props) =>
    props.tag === "single-job-page" &&
    css`
      border-radius: 20px;
    `}

  ${(props) => props.theme.breakpoints.down("tabletS")} {
    height: 58vh !important;
  }

  ${(props) => props.theme.breakpoints.down("mobileL")} {
    height: 52vh !important;
  }
`);

// Skeleton Components //
export const SkeletonStyled = styling(Skeleton)({
  //
});

export const JobBannerShareIconSkeleton = styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 10%;
    right: 4%;
  }
`;

export const JobBannerLogoSkeleton = withTheme(styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 10%;
    left: 5%;
    border-radius: 10px;
    width: 20%;
    height: 110px;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      left: 34%;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      left: 35%;
    }
  }
`);

export const JobBannerButtonSkeleton = styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    bottom: 10%;
    right: ${(props) => props.$right || "20%"};
    border-radius: 20px;
  }
`;

export const JobBannerJobTitleSkeleton = withTheme(styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    bottom: 30%;
    left: 4%;
    margin: 12px;
    border-radius: 10px;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      left: 31%;
      bottom: 50%;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      left: 30%;
      bottom: 50%;
    }
  }
`);

export const JobBannerCompanyNameSkeleton = styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
  }
`;

export const JobBannerLocationSkeleton = styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
  }
`;

export const NavigationContainerSkeleton = styled(Skeleton)`
  && {
  }
`;

export const SaveJobButtonSkeleton = withTheme(styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    bottom: 25%;
    right: ${(props) => props.$right || "20%"};
    border-radius: 20px;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      width: 35% !important;
      bottom: 220%;
      right: 34%;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      width: 40% !important;
      bottom: 220%;
      right: 30%;
    }
  }
`);

export const ApplyNowButtonSkeleton = withTheme(styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    bottom: 25%;
    right: ${(props) => props.$right || "20%"};
    border-radius: 20px;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      width: 35% !important;
      bottom: 140%;
      right: 34%;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      width: 40% !important;
      bottom: 160%;
      right: 30%;
    }
  }
`);

export const JobInfoTitle = styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
    max-width: 100%;
  }
`;

export const JobInfoDescription = styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
    max-width: 100%;
  }
`;

export const CompanyBannerImageSkeleton = withTheme(styled(Skeleton)`
  && {
    margin: 0px 0 12px 0px;
    border-radius: 20px;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      width: ${(props) => (props.tag === "single-job-page" ? "90%" : "100%")};
      height: 50vh;
    }
  }
`);

export const CompanyBannerDescSkeleton = withTheme(styled(Skeleton)`
  && {
    margin: 0px 0 12px 0px;
    border-radius: 20px;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      width: ${(props) => (props.tag === "single-job-page" ? "90%" : "100%")};
      height: 50vh;
    }
  }
`);

export const CompanyBannerLogoSkeleton = withTheme(styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 25%;
    left: 4%;
    border-radius: 10px;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      left: 4%;
      top: 20%;
      width: 56px !important;
      height: 56px !important;
    }

    ${(props) => props.theme.breakpoints.down("mobile")} {
      left: 4%;
      top: 5%;
    }
  }
`);

export const CompanyBannerButtonSkeleton = withTheme(styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    right: 15%;
    bottom: 0%;
    border-radius: 20px;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      visibility: hidden;
    }
  }
`);

export const CompanyBannerSaveButtonSkeleton = withTheme(styled(Skeleton)`
  && {
    margin-right: 12px;
    position: absolute;
    z-index: 2;
    right: 5%;
    bottom: 0%;
    border-radius: 20px;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      visibility: hidden;
    }
  }
`);

export const CompanyBannerTabletSaveSkeleton = withTheme(styled(Skeleton)`
  && {
    ${(props) => props.theme.breakpoints.up("tablet")} {
      visiblity: hidden;
    }
    ${(props) => props.theme.breakpoints.down("tablet")} {
      position: absolute;
      z-index: 2;
      left: 50%;
      border-radius: 20px;
    }
  }
`);

export const CompanyBannerTabletApplySkeleton = withTheme(styled(Skeleton)`
  && {
    ${(props) => props.theme.breakpoints.up("tablet")} {
      visiblity: hidden;
    }
    ${(props) => props.theme.breakpoints.down("tablet")} {
      position: absolute;
      z-index: 2;
      left: 25%;
      border-radius: 20px;
    }
  }
`);

export const CompanyBannerJobNameSkeleton = withTheme(styled(Skeleton)`
  && {
    margin: 12px 12px 0px 0px;
    border-radius: 10px;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      margin: 0px;
      width: 90% !important;
    }
  }
`);

export const CompanyBannerIndustrySkeleton = withTheme(styled(Skeleton)`
  && {
    margin: 12px 12px 0px 0px;
    border-radius: 10px;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      width: 22% !important;
    }
  }
`);

export const CompanyBannerJobCountSkeleton = withTheme(styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      width: 22% !important;
    }
  }
`);

export const CompanyBannerJobDaysAgo = withTheme(styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
    position: absolute;
    right: 5%;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      visibility: hidden;
    }
  }
`);

export const CompanyInfoTitleSkeleton = styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 5%;
    left: 5%;
    border-radius: 10px;
  }
`;

export const CompanyInfoDescriptionTitleSkeleton = styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 9%;
    left: 5%;
    border-radius: 10px;
  }
`;

export const CompanyInfoDescriptionSkeleton = styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 12%;
    left: 5%;
    border-radius: 10px;
  }
`;

export const CompanyJobRequirementTitleSkeleton = styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 17%;
    left: 5%;
    border-radius: 10px;
  }
`;

export const CompanyJobRequirementDescTitleSkeleton = styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 21%;
    left: 5%;
    border-radius: 10px;
  }
`;

export const CompanyJobRequirementDescSkeleton = styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 24%;
    left: 5%;
    border-radius: 10px;
  }
`;

export const CompanyBenefitTitleSkeleton = styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 29%;
    left: 5%;
    border-radius: 10px;
  }
`;

export const CompanyBenefitCardeSkeleton = withTheme(styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 32%;
    left: 5%;
    border-radius: 10px;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      width: 60% !important;
    }
  }
`);

export const CompanyProfileTitleSkeleton = styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 49%;
    left: 5%;
    border-radius: 10px;
  }
`;

export const CompanyProfileCardeSkeleton = styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 52%;
    left: 5%;
    border-radius: 10px;
  }
`;

export const CompanyOfficeTitleSkeleton = styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 71%;
    left: 5%;
    border-radius: 10px;
  }
`;

export const CompanyOfficeCardeSkeleton = withTheme(styled(Skeleton)`
  && {
    position: absolute;
    z-index: 2;
    top: 74%;
    left: 5%;
    border-radius: 10px;
  }
`);

export const OurPeopleTitleSkeleton = styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
  }
`;

export const OurPeopleVideoSkeleton = withTheme(styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
    width: 45%;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      width: 95%;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      width: 92%;
    }
  }
`);

export const OurPeopleDescriptionSkeleton = styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
  }
`;

export const OurPeopleCaptionSkeleton = styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
    width: ${(props) => (props.$max_width ? "30%" : "20%")};
  }
`;

export const OurBenefitSlotSkeleton = withTheme(styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
    width: 100%;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      width: 100%;
    }
  }
`);

export const OurMediaImagesSkeleton = styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
    width: 100%;
    max-width: 100%;
  }
`;

export const OurOfficeMapSkeleton = styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
    width: 90%;
    position: relative;
    align-self: center;
  }
`;

export const OurOfficeTextSkeleton = styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
  }
`;

export const OurJobTitleSkeleton = styled(Skeleton)`
  && {
    margin: 12px 30px;
    border-radius: 10px;
  }
`;

export const OurJobFilterSkeleton = styled(Skeleton)`
  && {
    margin: 12px 30px;
    border-radius: 10px;
  }
`;

export const OurJobCardSkeleton = withTheme(styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
    width: 25vw;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      width: 83vw;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      width: 83vw;
    }
  }
`);

export const OurMoreTitleSkeleton = styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
  }
`;

export const OurMoreVideoSkeleton = styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
    width: 100%;
  }
`;

export const PaginationCardSkeleton = withTheme(styled(Skeleton)`
  && {
    margin: 4% 4% 2%;
    height: 20vh;
    border-radius: 10px;

    ${(props) => props.theme.breakpoints.between("tablet", "lg")} {
      width: 100%;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      height: 50vh;
    }
  }
`);

export const RecommendedJobsSliderSkeleton = styled(Skeleton)`
  && {
    margin: 12px;
    border-radius: 10px;
  }
`;

export const PaginationPageNumbersSkeleton = styled(Skeleton)`
  && {
    border-radius: 10px;
    padding-bottom: 5%;
  }
`;

// Box Components //
export const CompanyBannerJobDetailSkeleton = styled(Box)`
  && {
    background-color: ${Color.grey};
    border-radius: 1.25rem;
    height: ${(props) => (props.$companyInfo ? "50rem" : "22rem")};
    display: ${(props) => (props.$flex ? "flex" : "block")};
    justify-content: ${(props) => (props.$flex ? "center" : "flex-start")};
    align-items: ${(props) => (props.$companyInfo ? "flex-start" : "center")};
    padding-top: ${(props) => (props.$companyInfo ? "12rem" : "0px")};
    margin: 0 0 1.125rem 0;
    width: 100%;
  }
`;

export const AiSummarySkeleton = styled(CircularProgress)`
  && {
    display: flex;
    height: 13.75rem;
    background-color: ${Color.grey};
    border-radius: 1.25rem;
    margin: 0 0 1.125rem 0;
    justify-content: center;
    align-items: center;
  }
`;

export const JobContentSkeletonContainer = withTheme(styled(Box)`
  && {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 1.25rem;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      column-gap: 0;
    }
  }
`);

export const JobHighlightSkeleton = styled(Box)`
  && {
    background-color: ${Color.grey};
    border-radius: 1.25rem;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    margin: 0 0 1.125rem 0;
    width: 20%;
    min-width: 300px;
  }
`;

export const AiSummarySkeletonContainer = styling(Box)({
  backgroundColor: "#F7F7FF",
  border: `0.5px solid ${Color.grey}`,
  borderRadius: "20px",

  display: "flex",
  flexDirection: "column",
  gap: "0.75rem",

  padding: "0.75rem",
});

export const AiSummaryBox = styling(Box)({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  alignItems: "center",
  columnGap: "0.5rem",
});

export const AiJobSummaryEmptySkeletonContainer = styling(Box)((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
  width: "22.5rem",
  height: "32.25rem",
  position: "sticky",
  top: 152,
  border: "2px dashed rgba(81, 42, 204, 0.2)",
  borderRadius: "1.25rem",

  [props.theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));
